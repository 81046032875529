import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars";
import Aboveinfo from "../components/aboveinfo.js";

import { aboveDetail, getLatestTransaction } from "../helper/common.js";
import { shortText, formatNumber } from "../helper/custom";
import config from "../config/config";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [totalParticipant, settotalParticipant] = useState(0);
  const [recentUser, setrecentUser] = useState(0);
  const [contractBal, setcontractBal] = useState(0);
  const [transList, settransList] = useState([]);
  const [isList, setisList] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getInfo();
    getLatest();
  }, []);

  async function getInfo() {
    var { totalUsers, todayjoin, balance } = await aboveDetail();
    settotalParticipant(totalUsers);
    setrecentUser(todayjoin);
    var volume = parseFloat(todayjoin) * 25;
    volume = formatNumber(volume);
    setcontractBal(volume);
  }

  async function getLatest() {
    setisList(true);
    try {
      var response = await getLatestTransaction();
      settransList(response);
      setisList(false);
    } catch (err) {
      setisList(false);
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="page_header">
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <img
            src={require("../assets/images/banimg.png")}
            className="img-fluid"
            alt="img"
          />
          <h6>THE WORLD'S NO.1 PLATFORM</h6>
          <h1>DECENTRALIZED 2.0</h1>
        </div>
      </div>

      <section className="text-center py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 m-auto">
              <h2 className="h2tag">YOU ARE SMART</h2>
              <h5 className="h5tag mb-0">YOU DESERVE BETTER FINANCIAL STATUS</h5>
              <h2 className="h2tag">JOIN THE WORLD OF <br /> B-SMARTCASH</h2>
              <h5 className="h5tag mb-0">EXPERIENCE THE WORLD'S BEST DECENTRALIZED SMART CONTRACT PROGRAM</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="global">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              {/* <h5 className="h5tag mb-0">INTERNATIONAL COMMUNITY</h5>
              <h2 className="h2tag">GLOBAL BUSINESS</h2> */}
              {/* <h5 className="h5tag mb-0">YOU ARE SMART</h5> */}
              <h2 className="h2tag">YOUR SMART WORK YIELDS GOOD RESULTS</h2>
              <p className="para">
                This is a smart contract which is completely decentralized and
                entirely developed using the next generation technology on the
                blockchain using the new age WEB 3.0 wallet. It is a 100 %
                distribution program in four by four (4 X 4) matrix, developed
                with a motive to provide a good supportive system to all the
                working and non-working force to earn good amount of USDT crypto
                assets and thereby to attain a good financial position in their
                lives. B-SMARTCASH is the new age technological revolution in
                the world of crypto. This is going to be major game changer.
              </p>
            </div>
            <div
              className="col-lg-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              {/* <img src={require("../assets/images/global.png")} className="img-fluid" alt="img" /> */}
              <div className="img_box_out">
                <div className="img_box">
                  <div className="flx">
                    {/* <div className="col-md-6"> */}
                    <div className="box">
                      <div>
                        <div className="imgb">
                          <img
                            src={require("../assets/images/g1.png")}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <p>RISK FREE</p>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-md-6"> */}
                    <div className="box">
                      <div>
                        <div className="imgb">
                          <img
                            src={require("../assets/images/g2.png")}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <p>Transparent</p>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-md-6"> */}
                    <div className="box">
                      <div>
                        <div className="imgb">
                          <img
                            src={require("../assets/images/g4.png")}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <p>100% Stable</p>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-md-6"> */}
                    <div className="box">
                      <div>
                        <div className="imgb">
                          <img
                            src={require("../assets/images/g3.png")}
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <p>Decentralized</p>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p_activity">
        <div className="container">
          <div className="head" data-aos="fade-up" data-aos-duration="1000">
            <h5 className="h5tag mb-0">PLATFORM </h5>
            <h2 className="h2tag">RECENT ACTIVITY</h2>
            {isList && <i className="fas fa-spinner fa-spin"></i>}
          </div>

          <div
            className="activity_box"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <Scrollbars style={{ width: "100%", height: 450 }}>
              {!isList && transList && transList.length == 0 && (
                <div className="text-center activity_scroll">
                  No recent activity
                </div>
              )}
              <div className="activity_scroll">
                {!isList &&
                  transList &&
                  transList.length > 0 &&
                  transList.map((item, i) => {
                    var text = "";
                    var logo = "wallet.png";
                    if (item && item.event === "regUser") {
                      text = "New user joined";
                      logo = "user_prf.png";
                    } else if (item && item.event === "claimEarnings") {
                      text = `User withdraw ${item.amount} USDT`;
                      logo = "wallet.png";
                    } else if (item && item.event === "claimEarnings") {
                      text = "User Upgraded wallet";
                      logo = "wallet.png";
                    }

                    var txid = shortText(item.hash);

                    return (
                      <div className="activity_box_flx">
                        <div>
                          <div className="flxbox">
                            <div>
                              <img
                                src={require("../assets/images/usdt.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            </div>
                            <div>
                              <img
                                src={require(`../assets/images/${logo}`)}
                                className="img-fluid"
                                alt="img"
                              />
                            </div>
                            {item.event === "regUser" ? (
                              <div>
                                <p className="violet">{text}</p>
                              </div>
                            ) : (
                              <div>
                                <div className="btn">{txid}</div>
                              </div>
                            )}
                            {item.event === "regUser" ? (
                              <div>
                                <div className="btn">{txid}</div>
                              </div>
                            ) : (
                              <div>
                                <p className="green">{text}</p>
                                {/* <p className='green' >User Claimed <span>x4</span></p> */}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="rightbtn">
                          <p>
                            {/* <span>
                              {" "}
                              <a
                                href={config.txLink + "/tx/" + item.hash}
                                target="_blank"
                              >
                                <img
                                  src={require("../assets/images/arrow.png")}
                                  className="img-fluid me-2"
                                  alt="img"
                                />
                              </a>
                            </span> */}
                            <span> {item.timeago}</span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Scrollbars>

            {/* <button className='seemorebutn primary_btn'>
              See more
              <img src={require("../assets/images/seemore_arw.png")} className='img-fluid ms-2 arrow' alt='img' />
            </button> */}
          </div>
        </div>
      </section>

      <section className="plan_box" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <div className="maxbox">
            <div className="row">
              <div className="col-md-4">
                <div className="box">
                  <img
                    src={require("../assets/images/participants.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <h3>{totalParticipant}</h3>
                  <p>All Participants</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <img
                    src={require("../assets/images/busd.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <h3>{contractBal}</h3>
                  <p>24 Hours Volume</p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box">
                  <img
                    src={require("../assets/images/participants.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <h3>{recentUser}</h3>
                  <p>Joined in 24 hours</p>
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="box">
                  <img src={require("../assets/images/usd.png")} className="img-fluid" alt="img" />
                  <h3>20809</h3>
                  <p>Total Earned USD</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="plan_exp">
        <div className="container">
          <div className="head" data-aos="fade-up" data-aos-duration="1000">
            <h5 className="h5tag">PLAN EXPLAINED</h5>
            <h2 className="h2tag">HOW IT WORKS?</h2>
            <p class="para" id="bcashpdf"><a href={config.baseUrl + "pdf/BSmartCash.pdf"} target="_blank">Click here</a> to download plan details</p>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <button className="primary_btn">25 USDT TO JOIN</button>
            <div className="img_box">
              <img
                src={require("../assets/images/plan_tree.png")}
                className="img-fluid"
                alt="img"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="quote_div">
                <p>Are you a seasoned crypto enthusiast or just starting your journey, B-SmartCash offers a chance to capitalize on the ever-expanding crypto market.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="quote_div">
                <p>Dare to dream bigger with B-SmartCash, where visionary minds unite to redefine the boundaries of financial prosperity.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="quote_div">
                <p>B-SmartCash opens the gateway to universal population take path towards Wealth and Limitless Opportunities.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="quote_div">
                <p>B-SmartCash ensures that your transactions are fully secure and transparent powered by robust BNB network.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <h2 className="h2tag mb-5">FAQ</h2>
          <div className="row">
            <div className="col-md-8">
              <div className="accordion" id="accordionExample">
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <h2 className="accordion-header" id="headingOnebcash">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOnebcash"
                      aria-expanded="true"
                      aria-controls="collapseOnebcash"
                    >
                      What is B-SmartCash
                    </button>
                  </h2>
                  <div
                    id="collapseOnebcash"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOnebcash"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        This is a smart contract which is completely
                        decentralized and entirely developed using the next
                        generation technology on the blockchain using the new
                        age WEB 3.0 wallet. It is a 100 % distribution program
                        in four by four (4 X 4) matrix.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="350"
                >
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Why do we use Smart contract and Decentralization?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Smart contract is self-executing program that run on
                        specified blockchain network and automate the execution
                        of contractual terms between parties. Smart contracts
                        allow for the creation of decentralized applications
                        (DApps) that can run without the need for intermediaries
                        or third-party services i.e., there will be no need of
                        admin, which itself will be a great transparent and
                        technically strong mechanism without human errors.{" "}
                        <b>Ultimately all the funds will be safe and secured</b>
                        .
                      </p>
                      <p>
                        Decentralization is a key feature of many
                        cryptocurrencies and blockchain networks. BNB smart
                        chain is largely used and most trusted for the smart
                        contract. It refers to the concept of distributing
                        control over a network among many different participants
                        rather than having a single central authority or
                        intermediary that controls the network.
                      </p>
                      <p>
                        In a decentralized system, data is stored on a
                        distributed ledger or blockchain that is maintained by a
                        network of nodes that validate and verify transactions.
                        This distributed ledger ensures that all participants on
                        the network have a copy of the same data, creating a
                        transparent and tamper-proof record of all transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is USDT?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Tether (USDT) is a stablecoin, a type of cryptocurrency pursuing a steady valuation.
                      </p>
                      {/* <p>
                        This very strict accounting and reconciliation process
                        necessitates a monthly audit in order to verify the
                        safety of investors' funds, as well as other security
                        measures put in place by the New York Department of
                        Financial Services (NYDFS) and other regulatory agencies
                      </p>
                      <p>
                        Additionally, USDT is one of the only existing
                        fiat-backed stablecoins with records that verify the
                        security of its physical reserves. Because of this, USDT
                        is considered one of the safest among the top stablecoin
                        in existence.
                      </p>
                      <p>
                        USDT is used as a means of payment that can be sent
                        internationally and verified on the public blockchain
                        ledger within seconds. Today, with more than 3 million
                        active holders and 18 billion coins in circulation, USDT
                        is accepted as payment by a variety of businesses and
                        individuals globally.
                      </p> */}
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <h2 className="accordion-header" id="headingthree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsethree"
                      aria-expanded="false"
                      aria-controls="collapsethree"
                    >
                      How USDT is safe?
                    </button>
                  </h2>
                  <div
                    id="collapsethree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingthree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Tether USDT is used by investors who want to avoid the volatility typical of cryptocurrencies while holding funds within the cryptosystem.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingfour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      What is the difference between stable coin and other
                      coins?
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingfour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A stablecoin is a token that has a non-volatile price
                        and other coins, per say Altcoins are a cryptocurrency
                        whose price is volatile in nature.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingfive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Why B-SmartCash is best alternative to trading?
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingfive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        The member need not panic about the volatility of the
                        crypto market. Here the registration USDT is very meagre
                        and Here the registration USDT is very meagre and the benefits you are going to receive are comparable. Once the
                        member is active and developing team then he/she will be
                        goes on receiving the benefits directly in to their
                        wallet. As per the members capacity the benefits will be
                        received
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingsix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      How much safe is B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapsesix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingsix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        It has been developed with new age technology on the
                        blockchain with 100% distribution. Members details were
                        not collected except for the wallet address which will
                        be connected via WEB 3.0 wallet for login. The complete
                        B-SmartCash is secured and safe because, it is mere
                        projection of the details from the blockchain which is
                        already in public domain which itself can not be
                        altered and hacked.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingseven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseseven"
                      aria-expanded="false"
                      aria-controls="collapseseven"
                    >
                      How can I join B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapseseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingseven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        You should have 25 USDT (pertaining to BEP 20) and
                        minimum of BNB (example 5$ worth BNB) towards the gas
                        fee in your personal Trust wallet (or) Meta mask wallet
                        and register yourself in the website by connecting your
                        wallet.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingeight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      From where I get benefits in B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapseeight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingeight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        All your earnings and benefits in B-SmartCash will be
                        received from other members activities.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingnine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenine"
                      aria-expanded="false"
                      aria-controls="collapsenine"
                    >
                      Can I withdraw my benefits from B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapsenine"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingnine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you can withdraw what ever benefits you have
                        received from B-SmartCash, it will be received in your
                        registered wallet only.
                      </p>
                      <p>
                        However, if your withdrawable benefits reach 25 USDT,
                        the same will be automatically pushed to your registered
                        wallet once in a day by the B-SmartCash. Every day the
                        system checks if your withdrawable wallet has 25 USDT
                        then it will push the same to your registered wallet.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingten">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseten"
                      aria-expanded="false"
                      aria-controls="collapseten"
                    >
                      Can I have multiple registrations for one wallet?
                    </button>
                  </h2>
                  <div
                    id="collapseten"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingten"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>No, only one registration is allowed for one wallet</p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingeleven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeleven"
                      aria-expanded="false"
                      aria-controls="collapseeleven"
                    >
                      What is the minimum USDT limit for withdrawal?
                    </button>
                  </h2>
                  <div
                    id="collapseeleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingeleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>No withdrawal limit, you can withdraw even a small amount of USDT, however before withdrawal please ensure that necessary BNB is available in your personal wallet towards network/gas fee of the transaction going to effect.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingtwelve">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetwelve"
                      aria-expanded="false"
                      aria-controls="collapsetwelve"
                    >
                      How do upgradations happen in B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapsetwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingtwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>Auto upgrade system has been enabled using with a world class automation technique if the necessary funds are available in upgrade wallet.</p>
                      <p>However, if any member wants to upgrade and the necessary funds are not available in upgrade wallet then he/she can deposit the required USDT using deposit button. Once deposited the system will automatically upgrade it.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="650"
                >
                  <h2 className="accordion-header" id="headingthirteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsethirteen"
                      aria-expanded="false"
                      aria-controls="collapsethirteen"
                    >
                      How can we get information from B-SmartCash?
                    </button>
                  </h2>
                  <div
                    id="collapsethirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingthirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>B-SmartCash telegram channel is available, which will provide necessary information when ever needed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/* <img src={require("../assets/images/faq.png")} className="img-fluid" alt="img" /> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
