import { Multicall } from "ethereum-multicall";
import moment from "moment";
import axios from 'axios';

import BcashABI from "../../ABI/Bcash.json";
import config from "../../config/config";

import { getFormatMulticall1, formatNumber } from "../../helper/custom";
import { connection } from "../../helper/connection";


export async function getLeaderShipInfo() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "autoleaderPoolinfo",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "autoleaderPoolinfo",
                        methodName: "autoleaderPoolinfo",
                        methodParameters: [address],
                    }
                ]
            }
        ];
        const results = await multicall.call(Contract);
        var isCurrentLevel = await getFormatMulticall1(results, "autoleaderPoolinfo", 0);
        isCurrentLevel = (isCurrentLevel && isCurrentLevel[0] && isCurrentLevel[0].hex) ? parseInt(isCurrentLevel[0].hex) : 0;

        var leadership = "";
        var logo = "";
        if (isCurrentLevel === 1) {
            leadership = "Primary";
            logo = "primary.png";
        } else if (isCurrentLevel === 2) {
            leadership = "Grand";
            logo = "grand.png";
        } else if (isCurrentLevel === 3) {
            leadership = "Royal";
            logo = "royal.png";
        } else if (isCurrentLevel === 4) {
            leadership = "Majestic";
            logo = "majestic.png";
        }

        return {
            leadership: leadership,
            leadershiplogo: logo
        }
    } catch (err) {
        console.log(err, 'errerr')
        return {
            leadership: "",
            leadershiplogo: ""
        }
    }

}

export async function getAutopoolAddress() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "autoleaderPoolinfo",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "autoleaderPoolinfo",
                        methodName: "autoleaderPoolinfo",
                        methodParameters: [address],
                    }
                ]
            }
        ];
        const results = await multicall.call(Contract);
        var isCurrentLevel = await getFormatMulticall1(results, "autoleaderPoolinfo", 0);
        isCurrentLevel = (isCurrentLevel && isCurrentLevel[0] && isCurrentLevel[0].hex) ? parseInt(isCurrentLevel[0].hex) : 0;

        var isExits = [-1, -1, -1, -1];
        if (isCurrentLevel > 0) {
            for (var l = 0; l < isCurrentLevel; l++) {
                isExits[l] = 1;
            }
        }

        return {
            pool1: isExits[0],
            pool2: isExits[1],
            pool3: isExits[2],
            pool4: isExits[3],
        }
    } catch (err) {
        return {
            pool1: -1,
            pool2: -1,
            pool3: -1,
            pool4: -1,
        }
    }

}

export async function getLeaderBoard(poolNo) {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var amt = await Contract.methods.leaderPoolAmounts(address, poolNo).call();
        amt = amt / 10 ** 18;
        amt = (parseFloat(amt) > 0) ? parseFloat(amt).toFixed(0) : 0;
        return amt;
    } catch (err) {
        return 0;
    }
}

export async function getautoPoolAmounts(poolNo) {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var amt = await Contract.methods.autoPoolAmounts(address, poolNo).call();
        amt = amt / 10 ** 18;
        amt = (parseFloat(amt) > 0) ? parseFloat(amt).toFixed(0) : 0;
        return amt;
    } catch (err) {
        return 0;
    }
}

export async function getLeaderBoardAutoPoolOld() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var info = await Contract.methods.autoleaderPoolinfo(address).call();

        var leadePool1Amount = info.leadePool1Amount / 10 ** 18;
        leadePool1Amount = formatNumber(leadePool1Amount, 2);
        var leadePool2Amount = info.leadePool2Amount / 10 ** 18;
        leadePool2Amount = formatNumber(leadePool2Amount, 2);
        var leadePool3Amount = info.leadePool3Amount / 10 ** 18;
        leadePool3Amount = formatNumber(leadePool3Amount, 2);
        var leadePool4Amount = info.leadePool4Amount / 10 ** 18;
        leadePool4Amount = formatNumber(leadePool4Amount, 2);

        var autopool1Amount = info.autopool1Amount / 10 ** 18;
        autopool1Amount = formatNumber(autopool1Amount, 2);
        var autopool2Amount = info.autopool2Amount / 10 ** 18;
        autopool2Amount = formatNumber(autopool2Amount, 2);
        var autopool3Amount = info.autopool3Amount / 10 ** 18;
        autopool3Amount = formatNumber(autopool3Amount, 2);
        var autopool4Amount = info.autopool4Amount / 10 ** 18;
        autopool4Amount = formatNumber(autopool4Amount, 2);

        var totalAmount = info.totalAmount / 10 ** 18;
        totalAmount = formatNumber(totalAmount, 2);

        return {
            lPrimary: leadePool1Amount,
            lGrand: leadePool2Amount,
            lRoyal: leadePool3Amount,
            lMajestic: leadePool4Amount,
            aPrimary: autopool1Amount,
            aGrand: autopool2Amount,
            aRoyal: autopool3Amount,
            aMajestic: autopool4Amount,
            total: totalAmount
        }
        // info;
    } catch (err) {
        console.log(err, 'errerrerr')
        return {
            lPrimary: 0,
            lGrand: 0,
            lRoyal: 0,
            lMajestic: 0,
            aPrimary: 0,
            aGrand: 0,
            aRoyal: 0,
            aMajestic: 0,
            total: 0
        }
    }
}

export async function getLeaderBoardAutoPool() {

    try {
        var get = await connection();
        var address = get.address;
        var info = await axios({
            method: "post",
            url: `${config.baseUrl}api/get-leader-pool`,
            data: { address: address }
        });
        console.log(info, 'resprespresp')
        info = (info && info.data && info.data.result) ? info.data.result : {};

        var leadePool1Amount = formatNumber(info.leaderPoolAmount1, 2);
        var leaderPoolAmount2 = formatNumber(info.leaderPoolAmount2, 2);
        var leaderPoolAmount3 = formatNumber(info.leaderPoolAmount3, 2);
        var leaderPoolAmount4 = formatNumber(info.leaderPoolAmount4, 2);

        var autoPoolAmount1 = formatNumber(info.autoPoolAmount1, 2);
        var autoPoolAmount2 = formatNumber(info.autoPoolAmount2, 2);
        var autoPoolAmount3 = formatNumber(info.autoPoolAmount3, 2);
        var autoPoolAmount4 = formatNumber(info.autoPoolAmount4, 2);

        var totalAmount = formatNumber(info.totalAmount, 2);

        return {
            lPrimary: leadePool1Amount,
            lGrand: leaderPoolAmount2,
            lRoyal: leaderPoolAmount3,
            lMajestic: leaderPoolAmount4,
            aPrimary: autoPoolAmount1,
            aGrand: autoPoolAmount2,
            aRoyal: autoPoolAmount3,
            aMajestic: autoPoolAmount4,
            total: totalAmount,
        }
    } catch (err) {
        return {
            lPrimary: 0,
            lGrand: 0,
            lRoyal: 0,
            lMajestic: 0,
            aPrimary: 0,
            aGrand: 0,
            aRoyal: 0,
            aMajestic: 0,
            total: 0
        }
    }
}

export function getDistributionDate() {

    const d = new Date();
    let day = d.getDay();

    var d1 = new Date();
    d1.setHours(11);
    d1.setMinutes(45);

    //var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    console.log(day, 'dayday')
    var nextday = null;
    var dayName = "";
    console.log(new Date(), 'new Date()new Date()')
    if (day === 0) {
        nextday = new Date();
        nextday.setDate(nextday.getDate() + 1);
        nextday = moment(nextday).format('ddd DD MMM YYYY');
    } else if (day === 1) {
        if (d1 > new Date()) {
            nextday = new Date();
            nextday = moment(nextday).format('ddd DD MMM YYYY');
        } else {
            nextday = new Date();
            nextday.setDate(nextday.getDate() + 3);
            nextday = moment(nextday).format('ddd DD MMM YYYY')
        }
    } else if (day === 2) {
        nextday = new Date();
        nextday.setDate(nextday.getDate() + 2);
        nextday = moment(nextday).format('ddd DD MMM YYYY');
    } else if (day === 3) {
        nextday = new Date();
        nextday.setDate(nextday.getDate() + 1);
        nextday = moment(nextday).format('ddd DD MMM YYYY')
    } else if (day === 4) {
        if (d1 > new Date()) {
            nextday = new Date();
            nextday = moment(nextday).format('ddd DD MMM YYYY');
        } else {
            nextday = new Date();
            nextday.setDate(nextday.getDate() + 4);
            nextday = moment(nextday).format('ddd DD MMM YYYY')
        }

    } else if (day === 5) {
        nextday = new Date();
        nextday.setDate(nextday.getDate() + 3);
        nextday = moment(nextday).format('ddd DD MMM YYYY')
    } else if (day === 6) {
        nextday = new Date();
        nextday.setDate(nextday.getDate() + 2);
        nextday = moment(nextday).format('ddd DD MMM YYYY')
    }
    // var getDate = nextday.getDate()
    //console.log(getDate)
    var resp = "-";
    var resp1 = "-";
    if (nextday) {
        resp = nextday;
        resp1 = "11:45 AM";
    }

    return {
        resp,
        resp1
    };
}